<template>
  <div>
    <div @click="IndexNew(newsData.slug)">
      <b-card tag="article" class="mx-auto my-4" border-variant="danger">
        <!-- :title="newsData.title" -->

        <b-row class="no-gutters">
          <b-col cols="12">
            <div class="img-wrap ratio-40">
              <div class="img-content card-img">
                <b-img
                  fluid
                  :src="newsData.main_photo && newsData.main_photo.path"
                />
              </div>
            </div>
          </b-col>
          <b-col sm="9" cols="12">
            <h4 class="font-bold my-3">{{ newsData.title }}</h4>
            <span class="font-regular"
              ><img
                height="20px"
                :src="require('@/assets/imgs/shape-copy.png')"
              /><span class="mx-2">{{ newsData.date.date }}</span></span
            >
            <span class="mx-3 font-regular"
              ><img
                height="20px"
                :src="require('@/assets/imgs/copy.png')"
              /><span class="mx-2">{{ newsData.publisher }}</span></span
            >
            <!-- <b-card-text
              class="font-regular mt-3"
              v-html="newsData.description"
            ></b-card-text> -->
            <read-more
              class="card-text read-more"
              more-str=""
              :text="newsData.description ? newsData.description : ''"
              :max-chars="90"
            ></read-more>
          </b-col>
          <!-- <b-col cols="3" class="text-center">
            <b-button class="mainColor SubTitle my-3" size="sm" pill
              ><img
                class="ImgSize mx-2"
                :src="require('@/assets/imgs/share.svg')"
              />
              <span height="100%" class="px-2 font-regular"
                >{{ $t("button.share") }}
              </span></b-button
            >
          </b-col> -->
        </b-row>
        <!-- <b-row class="mx-3 my-3">
          <span class="px-2"
            ><img :src="require('@/assets/imgs/shape-copy.png')" />{{
              newsData.date.date
            }}</span
          >
          <span class="px-2"
            ><img :src="require('@/assets/imgs/copy.png')" />{{
              newsData.title
            }}</span
          >
        </b-row> -->

        <!-- <b-button
                width="8vw"
                height="5vh"
                justify="center"
                class="button button--md button--outline-primary my-4 mx-0"
                @click="IndexNew(newsData.slug)"
        >{{ $t('button.details') }}</b-button>-->
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsComponent",
  props: {
    newsData: {
      type: Object,
      default: () => {
        return {
          main_photo: {
            path: ""
          },
          date: {},
          description: "",
          publisher: "",
          slug: ""
        };
      }
    },
    range: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      noPublisher: "No Publisher"
    };
  },
  methods: {
    IndexNew(slug) {
    
      this.$router.push({ path: `/blog/${slug}` });
    }
  }
};
</script>

<style scoped>
.card {
  border: none !important;
  box-shadow: none !important;
}
.card-img-top {
  width: 100%;
  height: 15vw;
  object-fit: fill;
}
.mainColor {
  background-color: #1f1f98;
}
.mainText {
  color: #1f1f98;
}
.TitleSize {
  font-size: 2rem;
  font-weight: bold;
}
.SubTitle {
  font-size: 1rem;
}

.ImgSize {
  width: 1.2vw;
}
</style>
